import React, { useEffect } from 'react';
import { Box, Slide } from '@chakra-ui/react';
import { Link, Events } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Social from '../common/Social';
import styles from '../../styles/MobileMenu.module.css';

function MobileMenu({ isOpen, onClose }) {
  useEffect(() => {
    Events.scrollEvent.register('begin', function () {
      document.querySelector('#closeMenu').click();
    });
  }, []);

  return (
    <Slide direction="left" in={isOpen} style={{ zIndex: 99999 }}>
      <Box width="100%" height="100vh" backgroundColor="#FFFFFF" pt="60px">
        <Box position="absolute" left={5} top={5} zIndex={99999}>
          <button id="closeMenu" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} color="black" size="2x" />
          </button>
        </Box>
        <Box>
          <Link className={styles.link} to="catalogo">
            Catálogo
          </Link>
          <Link className={styles.link} to="about" offset={-70}>
            Quiénes somos
          </Link>
          <Link className={styles.link} to="news" offset={-100}>
            Novedades
          </Link>
          <Link className={styles.link} to="contact" offset={-100}>
            Contacto
          </Link>
          <Link className={styles.link} to="brands" offset={-100}>
            Nuestras marcas
          </Link>
        </Box>
        <Box px="50px" pt="30px">
          <Social color="#a3a3a3" size="2x" spacing="20px" />
        </Box>
      </Box>
    </Slide>
  );
}

export default MobileMenu;
