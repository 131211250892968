import React from 'react';
import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import titles from '../../styles/Titles.module.css';

function About() {
  return (
    <Container maxW={['100%', '100%', '100%', '1440']} padding={[0]}>
      <Box position="absolute" zIndex={0}>
        <Image
          display={['none', 'block', 'block', 'block']}
          src="/assets/images/fondo_about_home.jpg"
        ></Image>
      </Box>
      <Image
        display={['block', 'none', 'none', 'none']}
        width="100%"
        src="/assets/images/fondo_about_mobile_home.jpg"
      ></Image>
      <Flex justifyContent="flex-end" minH={[0, 0, 0, '615']}>
        <Box
          w={['100%', '90%', '50%', '50%', '36%']}
          backgroundColor={[
            '#d7d9dc',
            'transparent',
            'transparent',
            'transparent',
          ]}
          mr={['0', '10', '10', '20', '60']}
          pb={[10, 0, 0, 0]}
          px={[5, 0]}
          zIndex={1}
        >
          <Heading
            className={titles.titleAbout}
            fontSize={['3rem', '3rem', '2.3rem', '3.3rem', '3.3rem']}
            lineHeight={['3rem', '3rem', '2.3rem', '3.3rem', '3.3rem']}
            my={[10, 10, 5, 10, 10]}
          >
            Las mejores marcas de Argentina
          </Heading>
          <Text className={titles.paragraphAbout} mb="10">
            Desde 1968, somos representantes comerciales de las mejores marcas
            de pesca, outdoor y náutica en Argentina. Con una vasta experiencia
            y vendedores especializados, abastecemos a todo el país desde
            nuestros depósitos situados en Villa Domínico, partido de
            Avellaneda, y en Ricardo Rojas, partido de General Pacheco.
          </Text>
          <Text className={titles.paragraphAbout}>
            {' '}
            Contamos con un amplio stock, personal capacitado y un sistema de
            gestión que nos permiten una rápida preparación y control de los
            pedidos. Con flota propia, visitamos diariamente los expresos que
            llevan los pedidos a cada rincón del país.
          </Text>
        </Box>
      </Flex>
    </Container>
  );
}

export default About;
