import React, { useEffect } from 'react';
import { Container, Box, Flex, useDisclosure } from '@chakra-ui/react';
import Logo from '../common/Logo';
import Social from '../common/Social';
import Nav from './Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../../styles/Header.module.css';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import MobileMenu from './MobileMenu';

function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = e => {
    const header = document.querySelector('#header');
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header.classList.add('stickyHeader')
      : header.classList.remove('stickyHeader');
  };

  return (
    <Box
      id="header"
      pos={['fixed', 'absolute']}
      className={styles.headerWrapper}
      py="4"
    >
      <MobileMenu isOpen={isOpen} onClose={onClose} />
      <Container maxW="1446">
        <Flex align="center" justifyContent="space-between">
          <Logo />
          <Box display={['none', 'flex', 'flex', 'flex']}>
            <Nav />
          </Box>
          <Box display={['none', 'flex', 'flex', 'flex']}>
            <Social />
          </Box>
          <Box display={['flex', 'none', 'none', 'none']}>
            <button onClick={onOpen}>
              <FontAwesomeIcon icon={faBars} color="white" size="2x" />
            </button>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

export default Header;
