import React from 'react';
import { HStack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faYoutube,
  faInstagram,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';
import {faTiktok} from "@fortawesome/free-brands-svg-icons/faTiktok";

function Social({
  color = 'white',
  size = 'lg',
  fbsize = 'lg',
  spacing = '10px',
}) {
  return (
    <HStack spacing={spacing}>
      <a href="https://www.instagram.com/kierosrl/" rel="noreferrer" target="_blank">
        <FontAwesomeIcon icon={faInstagram} color={color} size={size} />
      </a>
      <a href="https://www.facebook.com/kierosrl" rel="noreferrer" target="_blank">
        <FontAwesomeIcon icon={faFacebookF} color={color} size={fbsize} />
      </a>
      <a href="https://youtube.com/@kieroyoutube" rel="noreferrer" target="_blank">
        <FontAwesomeIcon icon={faYoutube} color={color} size={size} />
      </a>
        <a href="https://www.tiktok.com/@kierosrl" rel="noreferrer" target="_blank">
        <FontAwesomeIcon icon={faTiktok} color={color} size={size} />
      </a>
    </HStack>
  );
}

export default Social;
