import { Container, Flex } from '@chakra-ui/react';
import React from 'react';
import ProductsList from './ProductsList';
import ProductsSidebar from './ProductsSidebar';

function ProductsComponent() {
  return (
    <>
      <Container maxW="1146" my="10">
        <Flex flexWrap={['wrap']}>
          <ProductsSidebar />
          <ProductsList />
        </Flex>
      </Container>
    </>
  );
}

export default ProductsComponent;
