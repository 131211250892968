import React from 'react';
import { Box, Image } from '@chakra-ui/react';

function PescaHeader() {
  return (
    <Box
      backgroundImage={[
        '/assets/images/pesca_head_mobile.jpg',
        '/assets/images/pesca_head.png',
      ]}
      height="328px"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
    ></Box>
  );
}

export default PescaHeader;
