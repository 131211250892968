import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import WebFont from 'webfontloader';
import Home from './pages/Home';
import Catalogo from './pages/Catalogo';
import Pesca from './pages/Pesca';
import './styles/global.css';

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat'],
      },
    });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pesca" element={<Pesca />} />
    </Routes>
  );
}

export default App;
