import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import styles from '../../styles/Nav.module.css';
import { Link } from 'react-scroll';

function Nav() {
  const stylesLinks = {
    cursor: "pointer",
  };
  return (
    <Box className={styles.headerNav}>
      <HStack spacing="30px">
        <Link to="brands" spy={true} smooth={true} duration={1000} offset={100} style={stylesLinks}> 
          Marcas
        </Link>
        <Link to="news" spy={true} smooth={true} duration={1000} offset={-20} style={stylesLinks}>
          Novedades
        </Link>
        <Link to="about" spy={true} smooth={true} duration={1000} style={stylesLinks}>
          Quiénes somos
        </Link>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          duration={1000}
          offset={-20}
          style={stylesLinks}
        >
          Contacto
        </Link>
      </HStack>
    </Box>
  );
}

export default Nav;
