import React from 'react';
import { Box, Container, Heading } from '@chakra-ui/react';
import titles from '../../styles/Titles.module.css';
import ContactInfo from '../common/ContactInfo';

function Contact() {
  return (
    <Container maxW="1146" my={['10', '20']}>
      <Heading
        className={titles.dividerTitles}
        textTransform="uppercase"
        textAlign="center"
        as="h3"
      >
        Contactate con nosotros
      </Heading>
      <Box mt={['10', '20']}>
        <ContactInfo />
      </Box>
    </Container>
  );
}

export default Contact;
