import React from 'react';
import { Container, Box, Flex, Text } from '@chakra-ui/react';
import Logo from '../common/Logo';
import Social from '../common/Social';

import styles from '../../styles/Footer.module.css';

function Footer() {
  return (
    <Box className={styles.FooterWrapper} bg="#2c2c2c" py="4">
      <Container maxW="1446">
        <Flex
          align="center"
          justifyContent={['center', 'space-between']}
          flexWrap={['wrap', 'nowrap']}
        >
          <Flex
            alignItems="center"
            justifyContent={['center', 'space-between']}
            flexWrap={['wrap', 'nowrap']}
            order={[1, 0]}
          >
            <Box my={[5, 0]}>
              <Logo />
            </Box>
            <Text className={styles.footerCopy} ml={5} textAlign="center">
              © copyright 2022 - kiero SRL todos los derechos reservados.
            </Text>
          </Flex>
          <Social />
        </Flex>
      </Container>
    </Box>
  );
}

export default Footer;
