import React from 'react';
import { Box, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import styles from '../../styles/News.module.css';
import { useState, useEffect } from 'react';
import { urlBackend } from '../../utils/constants';
import { Link } from 'react-scroll';
function News() {
  let [novedades, setNovedades] = useState([])

  useEffect(() => {
    fetch(urlBackend + 'api/ultimas-novedades')
      .then((response) => {
        return response.json()
      })
      .then((response) => {
        setNovedades(response.data)

      })
  }, [])

  return (

    <Container maxW="1146" my="10">
      <Flex flexWrap="wrap">
        <Heading className={styles.headingNews} mb={[10]}>
          Novedades
        </Heading>
        {novedades.map((novedad, index) => {
          const compNovedad = (<Flex flexDirection={["column", "row"]} alignItems={["center", "center"]} justifyContent={["center", "space-between"]} mb="20">
            <Box w={['380px', '1115px']}>
              <Text className={styles.dateNews} mb="1">
                {novedad.fecha}
              </Text>
              <Heading className={styles.titleNews} mb="2">
                {novedad.titulo}
              </Heading>
              <Text w={['380px', '780px']} className={styles.textNews}>
                {novedad.cuerpo}
              </Text>
            </Box>
            <Box display={['flex', 'flex']}>
              {novedad.link ?
                <a href={novedad.link}>
                  <Image src={novedad.imagen_url} fit={'fill'} htmlWidth={275} htmlHeight={225} mt={[2, 0]} ></Image>
                </a>
                :
                <Image src={novedad.imagen_url} fit={'fill'} htmlWidth={275} htmlHeight={225} mt={[2, 0]} ></Image>
              }

            </Box>
          </Flex>);
          if (novedad.link) {
            return (
              <a href={novedad.link} target='_blank'>
                {compNovedad}
              </a>
            );
          }
          return compNovedad;

        })}
      </Flex>
    </Container>
  );
}

export default News;
