import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

function ContactInfoTwo() {
  return (
    <Flex
      wrap={['wrap', 'nowrap']}
      alignItems="center"
      justifyContent="center"
      mt={[5]}
      mb={[0, 10]}
    >
      <Box
        w={['100%', '100%', '100%', '25%']}
        mb={['10', '0']}
        textAlign="center"
      >
        <Flex alignItems="center" justifyContent="space-evenly">
          <Image src="/assets/images/location-pin.png" width="35px"></Image>
          <Box>
            <Text fontWeight="700" color="white">
              Camacuá 5055,
            </Text>
            <Text fontWeight="700" color="white">
              Villa Dominico
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box
        w={['100%', '100%', '100%', '25%']}
        mb={['10', '0']}
        textAlign="center"
      >
        <Flex alignItems="center" justifyContent="space-evenly">
          <Image src="/assets/images/celular.png" width="30px"></Image>
          <Text fontWeight="700" color="white">
            +54 11 4115 6059
          </Text>
        </Flex>
      </Box>
      <Box
        w={['100%', '100%', '100%', '25%']}
        mb={['10', '0']}
        textAlign="center"
      >
        <Flex alignItems="center" justifyContent="space-evenly">
          <Image src="/assets/images/mail.png" width="50px"></Image>
          <Text fontWeight="700" color="white">
            info@kierosrl.com.ar
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}

export default ContactInfoTwo;
