import React from 'react';
import { Container, Box, HStack, Flex } from '@chakra-ui/react';
import LogoBlack from '../LogoBlack.js';
import { Input } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../../../styles/HeaderTwo.module.css';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function HeaderTwo() {
  return (
    <>
      <Box id="header" className={styles.headerWrapper} py="4" mb="10px">
        <Container maxW="1173">
          <HStack align="center" justifyContent="space-between" spacing="10px">
            <LogoBlack />
            <Input placeholder="Buscar" />
            <FontAwesomeIcon icon={faShoppingBasket} color="black" size="lg" />
          </HStack>
        </Container>
      </Box>
      <Box mb={2}>
        <Container maxW="1173">
          <Flex className={styles.navigation} flexWrap="wrap">
            <Link to="/camping">Camping</Link>
            <Link to="/pesca">Pesca</Link>
            <Link to="/flyfishing">Fly Fishing</Link>
            <Link to="/indumentaria">Indumentaria</Link>
            <Link to="/marcas">Marcas</Link>
            <Link to="/donde-comprar">Donde comprar</Link>
          </Flex>
        </Container>
      </Box>
    </>
  );
}

export default HeaderTwo;
