import React from 'react';
import {
  Container,
  Flex,
  Heading,
  Image,
  Link,
  VStack,
} from '@chakra-ui/react';

import titles from '../../styles/Titles.module.css';
import buttons from '../../styles/Buttons.module.css';

function OurBrands() {
  return (
    <Container maxW="1146">
      <Heading
        className={titles.dividerTitles}
        textTransform="uppercase"
        textAlign="center"
        as="h3"
      >
        Nuestras marcas
      </Heading>
      <Flex
        wrap="wrap"
        alignItems="center"
        justifyContent="space-between"
        my="10"
      >
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/aquafloat.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/coleman.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/tfo.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/scientific_anglers.png"></Image>
        </VStack>
        {/* Nueva fila  */}
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/sasame.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/bote.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/pelican.png"></Image>
        </VStack>
        <VStack w={['45%', '45%', '33%', '25%', '25%']} h={['120', '150']} borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']} >
          <Image m="auto" src="/assets/images/marcas/wow.png"></Image>
        </VStack>
        {/* Nueva fila  */}
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/sportsstuff.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/ho.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/hyperlite.png"></Image>
        </VStack>
        <VStack w={['45%', '45%', '33%', '25%', '25%']} h={['120', '150']} borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}>
          <Image m="auto" src="/assets/images/marcas/chums.png"></Image>
        </VStack>
        {/* Nueva fila  */}
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/nite_ize.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/national_geographic.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/contigo.png"></Image>
        </VStack>
        <VStack w={['45%', '45%', '33%', '25%', '25%']} h={['120', '150']} borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}>
          <Image m="auto" src="/assets/images/marcas/scepter.png"></Image>
        </VStack>
        {/* Nueva fila  */}
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/moeller.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/minn_kota.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
          borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/humminbird.png"></Image>
        </VStack>
        <VStack w={['45%', '45%', '33%', '25%', '25%']} h={['120', '150']} borderBottom={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}>
          <Image m="auto" src="/assets/images/marcas/attwood.png"></Image>
        </VStack>
        {/* Nueva fila  */}
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/seaflo.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/lalizas.png"></Image>
        </VStack>
        <VStack
          w={['45%', '45%', '33%', '25%', '25%']}
          h={['120', '150']}
          borderRight={['none', 'none', 'none', 'none', '1px solid #bcbcbc']}
        >
          <Image m="auto" src="/assets/images/marcas/nuova_rade.png"></Image>
        </VStack>
        <VStack w={['45%', '45%', '33%', '25%', '25%']} h={['120', '150']} >
          {/*<Image m="auto" src="/assets/images/marcas/aquafloat.png"></Image>*/}
        </VStack>
      </Flex>
      <Flex justifyContent="center" alignItems="center" py="10">
        <Link href="https://catalogo.kierosrl.com.ar/" className={buttons.buttonLarge}>Ver catálogo</Link>
      </Flex>
    </Container>
  );
}

export default OurBrands;
