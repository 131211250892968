import React from 'react';
import { Image, Link } from '@chakra-ui/react';

function Logo() {
  return (
    <Link to="/">
      <Image src="/assets/images/logo_kiero_grey.png" />
    </Link>
  );
}

export default Logo;
