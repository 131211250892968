import React from 'react';
import Footer from '../components/footer/index.js';
import Header from '../components/header/index.js';
import About from '../components/modulos/About.js';
import Contact from '../components/modulos/Contact.js';
import Featured from '../components/modulos/Featured.js';
import News from '../components/modulos/News.js';
import OurBrands from '../components/modulos/OurBrands.js';
import SliderHome from '../components/modulos/SliderHome.js';

import { Element } from 'react-scroll';
import '../styles/global.css';

function Home() {
  return (
    <>
      <Header />
      <SliderHome />
      <Featured />
      <Element name="about">
        <About />
      </Element>
      <Element name="news">
        <News />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
      <Element name="brands">
        <OurBrands />
      </Element>
      <Footer />
    </>
  );
}

export default Home;
