import { Box, Container, Flex, Heading, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import buttons from '../../styles/Buttons.module.css';
import titles from '../../styles/Titles.module.css';
import { urlCatalogo } from "../../utils/constants";
import { useState, useEffect } from 'react';

let isMobile;
let items = [];
const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
};



export default function SliderHome() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  isMobile = width <= 768;
  for (let index = 1; index <= 6; index++) {
    const prefixMobile = isMobile ? 'mobile-' : '';
    items.push(<Box height="500">
      <Image
        w="100%"
        h="100%"
        objectFit="cover"
        src={`/assets/images/slider-home/slider-${prefixMobile}${index}.jpg`}
        alt="Imagen slider"
      />
    </Box>)
  }
  let componenteHeader;
  if (isMobile) {
    componenteHeader = <AliceCarousel
      touchMoveDefaultEvents
      autoPlay
      animationType="slide"
      autoPlayInterval={4000}
      animationDuration={800}
      disableButtonsControls
      disableDotsControls
      infinite
      items={items}
      responsive={responsive}
      controlsStrategy="alternate"
    />;
  } else {
    componenteHeader = <video loop="true" autoplay="autoplay" muted>
      <source src="/assets/video/VideoWeb_1920x530.mp4" type="video/mp4" />

    </video>;
  }
  return (
    <Box pos="relative">
      <Container maxW="1146" pos="relative">
        <Box
          pos="absolute"
          zIndex="1"
          top="20"
          bottom={[0, 'auto']}
          left={[0, 15]}
          w={['100%', '550px']}
        >
          <Flex
            alignItems={['center', 'flex-start']}
            flexDirection={['column']}
            justifyContent={['space-between', 'flex-start']}
            height={400}
          >
            <Heading
              className={titles.titleKiero}
              color="#ffffff"
              mt={[5, 10]}
              mb={[3]}
              textAlign={['center', 'initial']}
              fontSize={['2.3rem', '1.2rem', '3rem', '3rem']}
            >
              La Naturaleza nos Inspira
            </Heading>
            <Text color="#ffffff" mb="5" display={['none', 'flex']}>
              Las mejores marcas de Argentina
            </Text>
            <a href={urlCatalogo} className={buttons.buttonLargeWhite}>
              Ver catálogo
            </a>
          </Flex>
        </Box>
      </Container>
      <Box height={[500, 'auto']}>
        {componenteHeader}
      </Box>
    </Box>
  );
}
