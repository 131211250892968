import React from 'react';
import {
    Box,
    Container,
    Image,
    Link,
    Text,
    Heading,
    Flex,
} from '@chakra-ui/react';
import buttons from '../../styles/Buttons.module.css';
import titles from '../../styles/Titles.module.css';
import { urlCatalogo } from '../../utils/constants'
import { useState, useEffect } from 'react';
let isMobile;
function Featured() {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    isMobile = width <= 768;
    const suffixMobile = isMobile ? '_mobile' : '';

    return (
        <Container maxW="1146">
            <Flex
                wrap={['wrap', 'nowrap']}
                py="40px"
                justifyContent={[
                    'normal',
                    'space-between',
                    'space-between',
                    'space-between',
                ]}
            >
                <Box
                    width={['100%', '100%', '31%', '31%']}
                    position="relative"
                    mb={['20px', '0']}
                    overflow="hidden"
                    borderRadius="25px"
                    height={[395, 'auto', 'auto']}
                >
                    <Link href={`${urlCatalogo}productos/nautica`}>
                        <Image
                            width="100%"
                            height="100%"
                            objectFit="cover"
                            src={`/assets/images/nautica${suffixMobile}.png`}
                        ></Image>
                        <Box
                            position="absolute"
                            bottom={['20px', '40px', '40px']}
                            textAlign="center"
                            left="0"
                            right="0"
                        >
                            <Text className={titles.kieroBrand}>Kiero</Text>
                            <Heading
                                as="h3"
                                mb={['0px', '20px']}
                                fontSize={['3rem', '3rem', '2.6rem', '3rem']}
                                className={titles.titleKiero}
                            >
                                Náutica
                            </Heading>
                            <Text
                                className={`${buttons.buttonKiero} ${buttons.templ1}`}
                            >
                                Ver más
                            </Text>
                        </Box>
                    </Link>
                </Box>
                <Box
                    width={['100%', '100%', '31%', '31%']}
                    position="relative"
                    mb={['20px', '0']}
                    overflow="hidden"
                    borderRadius="25px"
                    height={[395, 'auto', 'auto']}
                >
                    <Link href={`${urlCatalogo}productos/pesca`}>
                        <Image
                            width="100%"
                            height="100%"
                            objectFit="cover"
                            src={`/assets/images/pesca${suffixMobile}.png`}

                        ></Image>
                        <Box
                            position="absolute"
                            bottom={['20px', '40px', '40px']}
                            textAlign="center"
                            left="0"
                            right="0"
                        >
                            <Text className={titles.kieroBrand}>Kiero</Text>
                            <Heading
                                as="h3"
                                mb={['0px', '20px']}
                                fontSize={['3rem', '3rem', '2.6rem', '3rem']}
                                className={titles.titleKiero}
                            >
                                Pesca
                            </Heading>
                            <Text
                                className={`${buttons.buttonKiero} ${buttons.templ2}`}
                                to="/"
                            >
                                Ver más
                            </Text>
                        </Box>
                    </Link>
                </Box>
                <Box
                    width={['100%', '100%', '31%', '31%']}
                    position="relative"
                    overflow="hidden"
                    borderRadius="25px"
                    height={[395, 'auto', 'auto']}
                    mb={['20px', '0']}
                >
                    <Link href={`${urlCatalogo}productos/outdoor`}>
                        <Image
                            width="100%"
                            height="100%"
                            objectFit="cover"
                            src={`/assets/images/outdoor${suffixMobile}.png`}
                        ></Image>
                        <Box
                            position="absolute"
                            bottom={['20px', '40px', '40px']}
                            textAlign="center"
                            left="0"
                            right="0"
                        >
                            <Text className={titles.kieroBrand}>Kiero</Text>
                            <Heading
                                as="h3"
                                mb={['0px', '20px']}
                                fontSize={['3rem', '3rem', '2.6rem', '3rem']}
                                className={titles.titleKiero}
                            >
                                Outdoor
                            </Heading>
                            <Text
                                className={`${buttons.buttonKiero} ${buttons.templ3}`}
                                to="/"
                            >
                                Ver más
                            </Text>
                        </Box>
                    </Link>
                </Box>
            </Flex>
        </Container>
    );
}

export default Featured;
