import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import products from '../../styles/Products.module.css';

function ProductsList() {
  return (
    <>
      <Box width={['100%', '70%']}>
        <Flex wrap={['wrap']} justifyContent="space-between">
          <Box width={['100%', '30%']} mb="20px">
            <Link to="/ficha">
              <Box
                border="1px solid #ececec"
                borderRadius="10px"
                textAlign="center"
                py="10px"
                mb="10px"
              >
                <Image margin="auto" src="/assets/images/product.png" />
              </Box>
              <Box>
                <Text className={products.brand}>Flambeau</Text>
                <h3 className={products.heading}>Balde porta carnada doble</h3>
                <Text my={2} className={products.code}>
                  Código: 301043001000
                </Text>
                <Text className={products.description}>
                  9 lt. apertura superior
                </Text>
              </Box>
            </Link>
          </Box>

          <Box width={['100%', '30%']} mb="20px">
            <Link to="/ficha">
              <Box
                border="1px solid #ececec"
                borderRadius="10px"
                textAlign="center"
                py="10px"
                mb="10px"
              >
                <Image margin="auto" src="/assets/images/product.png" />
              </Box>
              <Box>
                <Text className={products.brand}>Flambeau</Text>
                <h3 className={products.heading}>Balde porta carnada doble</h3>
                <Text my={2} className={products.code}>
                  Código: 301043001000
                </Text>
                <Text className={products.description}>
                  9 lt. apertura superior
                </Text>
              </Box>
            </Link>
          </Box>
          <Box width={['100%', '30%']} mb="20px">
            <Link to="/ficha">
              <Box
                border="1px solid #ececec"
                borderRadius="10px"
                textAlign="center"
                py="10px"
                mb="10px"
              >
                <Image margin="auto" src="/assets/images/product.png" />
              </Box>
              <Box>
                <Text className={products.brand}>Flambeau</Text>
                <h3 className={products.heading}>Balde porta carnada doble</h3>
                <Text my={2} className={products.code}>
                  Código: 301043001000
                </Text>
                <Text className={products.description}>
                  9 lt. apertura superior
                </Text>
              </Box>
            </Link>
          </Box>
          <Box width={['100%', '30%']} mb="20px">
            <Link to="/ficha">
              <Box
                border="1px solid #ececec"
                borderRadius="10px"
                textAlign="center"
                py="10px"
                mb="10px"
              >
                <Image margin="auto" src="/assets/images/product.png" />
              </Box>
              <Box>
                <Text className={products.brand}>Flambeau</Text>
                <h3 className={products.heading}>Balde porta carnada doble</h3>
                <Text my={2} className={products.code}>
                  Código: 301043001000
                </Text>
                <Text className={products.description}>
                  9 lt. apertura superior
                </Text>
              </Box>
            </Link>
          </Box>
          <Box width={['100%', '30%']} mb="20px">
            <Link to="/ficha">
              <Box
                border="1px solid #ececec"
                borderRadius="10px"
                textAlign="center"
                py="10px"
                mb="10px"
              >
                <Image margin="auto" src="/assets/images/product.png" />
              </Box>
              <Box>
                <Text className={products.brand}>Flambeau</Text>
                <h3 className={products.heading}>Balde porta carnada doble</h3>
                <Text my={2} className={products.code}>
                  Código: 301043001000
                </Text>
                <Text className={products.description}>
                  9 lt. apertura superior
                </Text>
              </Box>
            </Link>
          </Box>
          <Box width={['100%', '30%']} mb="20px">
            <Link to="/ficha">
              <Box
                border="1px solid #ececec"
                borderRadius="10px"
                textAlign="center"
                py="10px"
                mb="10px"
              >
                <Image margin="auto" src="/assets/images/product.png" />
              </Box>
              <Box>
                <Text className={products.brand}>Flambeau</Text>
                <h3 className={products.heading}>Balde porta carnada doble</h3>
                <Text my={2} className={products.code}>
                  Código: 301043001000
                </Text>
                <Text className={products.description}>
                  9 lt. apertura superior
                </Text>
              </Box>
            </Link>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default ProductsList;
