import { Box, Checkbox, Flex, Heading, Stack } from '@chakra-ui/react';
import React from 'react';
import styles from '../../styles/ProductPage.module.css';

function ProductsSidebar() {
  return (
    <>
      <Box width={['100%', '30%']}>
        <Box mb={10}>
          <h3 className={styles.productCountTitle}>
            <span className={styles.productCountNumber}>55</span>
            <span className={styles.productCountName}>productos</span>
          </h3>
        </Box>
        <Flex
          justifyContent={['space-around']}
          flexWrap={['wrap']}
          width={['100%']}
        >
          <Box mb={10} width={['50%', '100%']}>
            <Box mb={2}>
              <h4 className={styles.productSidebarTitle}>Tamaño</h4>
            </Box>
            <Stack spacing={1} direction="column">
              <Checkbox size="lg" colorScheme="orange">
                Extra small
              </Checkbox>
              <Checkbox size="lg" colorScheme="orange">
                Small
              </Checkbox>
              <Checkbox size="lg" colorScheme="orange">
                Medium
              </Checkbox>
              <Checkbox size="lg" colorScheme="orange">
                Large
              </Checkbox>
              <Checkbox size="lg" colorScheme="orange">
                Extra large
              </Checkbox>
            </Stack>
          </Box>
          <Box width={['50%', '100%']}>
            <Box mb={2}>
              <h4 className={styles.productSidebarTitle}>Color</h4>
            </Box>
            <Stack spacing={1} direction="column">
              <Checkbox size="lg" colorScheme="orange">
                Rojo
              </Checkbox>
              <Checkbox size="lg" colorScheme="orange">
                Blanco
              </Checkbox>
              <Checkbox size="lg" colorScheme="orange">
                Negro
              </Checkbox>
              <Checkbox size="lg" colorScheme="orange">
                Azul
              </Checkbox>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default ProductsSidebar;
