import React from 'react';
import { Container, Box, Flex, Text } from '@chakra-ui/react';
import LogoGrey from '../LogoGrey';
import Social from '../Social';

import styles from '../../../styles/FooterTwo.module.css';
import ContactInfoTwo from '../ContactInfoTwo';

function Footer() {
  return (
    <Box className={styles.FooterWrapper} bg="#2c2c2c" py="4">
      <Container maxW="1173">
        <ContactInfoTwo />
        <Flex justifyContent={['center', 'flex-start']}>
          <Box mb="20px">
            <LogoGrey />
          </Box>
        </Flex>
        <Flex
          justifyContent={['center', 'space-between']}
          alignItems="center"
          flexWrap="wrap"
        >
          <Text
            className={styles.footerCopy}
            textAlign="center"
            color="#9c9490"
            fontSize=".665rem"
            mb={[5, 0]}
          >
            © copyright 2021 - kiero SRL todos los derechos reservados.
          </Text>
          <Social size="2x" fbsize="lg" spacing="20px" order={[2, 1]} />
        </Flex>
      </Container>
    </Box>
  );
}

export default Footer;
