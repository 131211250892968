import React from 'react';
import HeaderTwo from '../components/common/HeaderTwo/index.js';
import FooterTwo from '../components/common/FooterTwo/index.js';
import PescaHeader from '../components/modulos/PescaHeader.js';
import ProductsComponent from '../components/products/ProductsComponents.js';
import '../styles/global.css';

function Home() {
  return (
    <>
      <HeaderTwo />
      <PescaHeader />
      <ProductsComponent />
      <FooterTwo />
    </>
  );
}

export default Home;
