import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

function ContactInfo() {
  return (
    <Flex wrap={['wrap', 'nowrap']} justifyContent="center">
      <Box
        w={['100%', '100%', '100%', '25%']}
        mb={['10', '0']}
        textAlign="center"
      >
        <Flex w="100px" h="100px" alignItems="center" mx="auto">
          <Image src="/assets/images/location-pin.png" mx="auto"></Image>
        </Flex>
        <Text fontWeight="700" mt="10">
          <a href="https://maps.app.goo.gl/73jgVitGpAaDnSkf7">
            Kiero Central <br />
            Camacuá 5055 <br />
            Villa Dominico
          </a>
        </Text>
      </Box>
      <Box
        w={['100%', '100%', '100%', '25%']}
        mb={['10', '0']}
        textAlign="center"
      >
        <Flex w="100px" h="100px" alignItems="center" mx="auto">
          <Image src="/assets/images/location-pin.png" mx="auto"></Image>
        </Flex>
        <Text fontWeight="700" mt="10">
          <a href="https://maps.app.goo.gl/HXekpFhyUgX9xFeR9">
            Kiero Norte <br />
            Carlos Delcasse 2879 <br />
            Ricardo Rojas
          </a>
        </Text>
      </Box>
      <Box
        w={['100%', '100%', '100%', '25%']}
        mb={['10', '0']}
        textAlign="center"
      >
        <Flex w="100px" h="100px" alignItems="center" mx="auto">
          <Image src="/assets/images/celular.png" mx="auto"></Image>
        </Flex>
        <Text fontWeight="700" mt="10">
          <a href="https://wa.me/5491168481727">Whatsapp:<br/> +54 9 11 6848 1727</a>
        </Text>
      </Box>
      <Box
        w={['100%', '100%', '100%', '25%']}
        mb={['10', '0']}
        textAlign="center"
      >
        <Flex w="100px" h="100px" alignItems="center" mx="auto">
          <Image src="/assets/images/celular.png" mx="auto"></Image>
        </Flex>
        <Text fontWeight="700" mt="10">
          <a href="tel:5491141156059">Teléfono:<br/>+54 11 4115 6059</a>
        </Text>
      </Box>
      <Box
        w={['100%', '100%', '100%', '25%']}
        mb={['10', '0']}
        textAlign="center"
      >
        <Flex w="100px" h="100px" alignItems="center" mx="auto">
          <Image src="/assets/images/mail.png" m="auto"></Image>
        </Flex>
        <Text fontWeight="700" mt="10">
          <a href="mailto:info@kierosrl.com.ar">info@kierosrl.com.ar</a>
        </Text>
      </Box>
    </Flex>
  );
}

export default ContactInfo;
