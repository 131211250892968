import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '768px',
  md: '1024px',
  lg: '1200px',
  xl: '1440px',
  '2xl': '1600px',
});

const theme = extendTheme({ breakpoints });

export default theme;
